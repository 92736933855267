<template>

    <div class="notification is-success">
        <div class="media">
            <div class="media-content has-text-centered	"> Richiesta inviata con successo!</div>
        </div>
    </div>

</template>

<script lang="ts">


export default {
    name : 'ThankYou',
}
</script>
